import { prismicClient } from '../prismic';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  prismic: {
    client: prismicClient
  },
  myMotion: {
    companyId: 54360
  },
  forms: {
    cloudFunctions: {
      url: 'https://send-email-i7hqoqg4aa-ey.a.run.app'
    }
  },
  vehiclePdf: {
    baseUrl: 'https://www.autohaus-range.de',
    cloudFunctions: {
      url: 'https://europe-west3-autohaus-range.cloudfunctions.net/vehicle-pdf-generator'
    }
  },
  metaPixel: {
    enabled: false
  },
  googleAnalytics: {
    enabled: false
  },
  vehicleSearch: {
    mode: 'autohaus-digital'
  },
  autoUncle: {
    configurationId: 'hylqcKlkGe'
  }
};
